<template>
	<div ref="definemain" class="define main-page">
		<div class="page-bg"></div>
		<div class="reportdefine-top">
			<div class="define-title">
				<div class="define-name"><div class="name-top">{{reportdata.count_info.nickname}}</div>的每日橘袋</div>
				<div class="define-msg">确认你每日方案的补剂内容</div>
			</div>
		</div>
		<div class="define-pros">
			<div class="pro-box" :class="pindex%2==0&&pindex+1==reportdata.select_re.length?'zuo noborderb':pindex%2==0&&pindex+2==reportdata.select_re.length?'zuo noborderb':pindex%2==1&&pindex+1==reportdata.select_re.length?'you noborderb':pindex%2==0&&pindex+2!=reportdata.select_re.length&&pindex+1!=reportdata.select_re.length?'zuo':'you'" v-for="( item , pindex ) in reportdata.select_re" :key="pindex" @click="thispro(pindex)">
				<div class="pro-img" @click="goprodetail(item.goods_id)"><img :src="item.goods_img" alt=""></div>
				<div class="pro-infobox" @click="goprodetail(item.goods_id)">
					<div class="pro-name">
						<div class="pro-name-box">
							<div class="lspan">评估推荐</div>
							<span class="spantxt">{{item.goods_name}}</span>
						</div>
						<span class="rnum fontsize">￥{{item.dayuseprice}}/天</span>
					</div>
					<div class="pro-msg fontsize">{{item.index_desc}}</div>
					<!-- <div class="pro-num"><div><span>{{item.re_day_use}} 颗/天</span><span class="rnum">￥{{item.shop_price*item.re_day_use}}/天</span></div><div class="status">{{item.status==3?'已售罄 ':item.status==4?'已下架':''}}</div></div> -->
					<div class="pro-num">
						<div class=" fontsize"><span>{{item.re_day_use}} 颗/天</span></div>
						<div class="nonum" v-if="item.status==3">已售罄</div>
						<div class="nonum" v-if="item.status==4">已下架</div>
					</div>
					<div class="pro-boxline show_mb" v-if="pindex+1!=reportdata.select_re.length"></div>
				</div>
				<div class="zhao" @click="goprodetail(item.goods_id)" v-if="item.status==3||item.status==4"></div>
			</div>
			<!-- <div class="tips-msgs">*为规避如药物营养素相互作用等风险，建议你在营养师或医师指引下进行补剂搭配</div> -->
		</div>
		<div class="buy-num">
			<div class="main-weight">
				<div class="buy-way" @click="showbuyways">{{buyplan[action].title}}({{buyplan[action].days}}天) <img class="choseimg"
					src="../../public/imgs/zhankai.png" alt=""></div>
				<div class="price"><span>￥</span>{{buyplan[action].planprice}}</div>
			</div>
		</div>
		<div class="buy-box">
			<div class="buy-btns">
				<div class="main-weight">
					<div class="day-price">
						<div class="day-num">每日 <span>{{reportdata.select_re.length}}</span> 种</div>
						<div class="day-money">￥{{buyplan[action].dayprice}}/天</div>
					</div>
					<div class="actions-btns">
						<div class="shopcar public-btn-css" @click="addcart">加入购物车</div>
						<div class="buythis public-btn" @click="buythis">购买方案</div>
					</div>
				</div>
			</div>
		</div>
		<Drawer :closable="false" :width="bodywidth" v-model="show">
			<div class="chose-box">
				<div class="chose-title">
					<img src="../../public/imgs/goback.png" alt="" @click="goclosechou"><span>选择你的方案</span>
				</div>
				<div class="type-list">
					<div class="type-btn" :class="item.action==1?'action':''" :key="i" v-for="( item , i ) in buyplan" @click="thisplan(i)">
						<img :src="item.thumb_url" alt="">
						<div class="type-txt theprice" :class="item.canspecial==0?'hui':''">
							<div class="type-name">{{item.title}}({{item.days}}天)</div>
							<div class="type-price"><span class="chose-price" :class="item.canspecial==1?'':'canno'">￥{{item.planprice}}</span><span
								class="lastprice" v-if="item.lastplanprice!=item.planprice">￥{{item.lastplanprice}}</span></div>
							<div class="tips tips1" v-if="item.plan_brief&&item.canspecial==1&&item.is_special==2">
								<div class="tips-msg">{{item.plan_brief}}</div>
								<div class="tipbottom"></div>
							</div>
							<div class="cannospecial" v-if="item.canspecial==0">每日方案价格{{item.price_limit}}元以上即可享受</div>
						</div>
						<!-- <div class="experience buy-btn">
							<span class="name" :class="item.canspecial==1?'':'canno'">{{item.title}}({{item.days}}天)</span>
							<div class="theprice">
								<span class="chose-price" :class="item.canspecial==1?'':'canno'">￥{{item.planprice}}</span>
								<span class="lastprice" v-if="item.lastplanprice!=item.planprice">￥{{item.lastplanprice}}</span>
								<div class="tips tips1" v-if="item.plan_brief&&item.canspecial==1&&item.is_special==2">
									<div class="tips-msg">{{item.plan_brief}}</div>
									<div class="tipbottom"></div>
								</div>
							</div>
							<div class="cannospecial" v-if="item.canspecial==0">每日方案价格{{item.price_limit}}元以上即可享受</div>
						</div> -->
					</div>
				</div>
				<div class="ok public-btn" @click="ok">确定</div>
			</div>
		</Drawer>
		<div class="rgba" @click="closepopup" v-if="showpopup"></div>
		<popup v-if="showpopup" :rdata="popdata" @getback="getbackinfo"></popup>
		<div class="closepopup" v-if="showpopup" @click="closepopup">
			<img src="../../public/imgs/no.png" alt="">
		</div>
	</div>
</template>

<script>
	import popup from '../components/popup.vue'
	export default {
		name: 'define',
		components: {
			popup
		},
		data: () => ({
			show: false,
			buyplan: '',
			cart: '',
			cart_id: '',
			tipsurl: 'https://mmbizurl.cn/s/yiDciNLJd',
			buytype: 1,
			canbuy: false,
			checked: 1,
			action: 0,
			type: 1,
			showpopup: false,
			reportdata: '',
			actionid: '',
			count_id: '',
			cart_list: [],
			price: '',
			info: '',
			testid: '5587',
			id: '',
			checkdata: '',
			popdata: '',
			cid: '',
			
			bodywidth:515
		}),
		watch: {
			$route() {
				let detailmain = this.$refs.detailmain
				detailmain.scrollTo(0, 0)
			}
		},
		mounted() {
			//console.log(document.body.clientWidth)
			var bw  = document.body.clientWidth
			this.bodywidth = bw<515?bw:515
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login'
				})
			}
			var that = this
			that.info = info
			//console.log(this.$route.query)
			this.count_id = this.$route.query.id
			this.bid = this.$route.query.bid
			this.testid = this.$route.query.id
			//请求购买方案
			this.$ajax.post(this.API.api.apiurl.getBuyPlanV2, {
					member_id: that.info.member_id,
				},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
				.then(res => {
					////console.log(res)
					if (res.data.code == 0) {
						var data = res.data.data
						//console.log(data)
						for(var i=0;i<data.length;i++){
							if(data[i].id==that.$route.query.bid){
								that.checked = i
							}
						}
						that.buyplan = data
						that.getCartOne()
					} else {
						//console.log('错误：' + res.data.message)
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		methods: {
			getCountInfoNew(e) {
				var that = this
				//获取报告详情3.0版本
				this.$ajax.post(this.API.api.apiurl.getCountInfoNew, {
						count_id: e,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
						var data = res.data.data
							//console.log(data)
							that.countinfo = data
						}else if(res.data.code == '无效报告！'){
							that.$router.replace({path:'404'})
						}  else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			goclosechou(){
				this.show = false
			},
			settleAccounts(id) {
				var that = this
				//结算页面获取数据
				this.$ajax.post(this.API.api.apiurl.settleAccounts, {
						cart_ids: id,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
						var data = res.data.data
						//console.log(data)
							var cart_list = data.cart_list
							//cart_list是否需要
							that.cart_list = cart_list
							//console.log(cart_list)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getbackinfo(e) {
				//console.log(e)
				var that = this
				// var checkdata = this.checkdata
				// var way = ''
				// if(checkdata.status==7){
				// 	way=''
				// }
				// //console.log(way)
				if (e.value == 1) {
					this.gopay()
				} else if (e.value == 0) {
					this.saveTheArrivalNotice()
					// window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100004266&idx=1&sn=b4b6aeb22c2375391eda621cb0899cf2&chksm=7d84d9074af3501188729533d69130a12eeb15de1ff0ecea4160e403de5896fa233fc588eb29#rd'
				} else if (e.value == 3) {
					// var address = ''
					var goodids = []
					var goodsdata = []
					// var addressList = that.addressList
					var cart_list = that.cart_list
					// for (var i = 0; i < addressList.length; i++) {
					// 	if (addressList[i].is_default == 1) {
					// 		address = addressList[i]
					// 	}
					// }
					// //console.log(address, cart_list)
					for (var j = 0; j < cart_list.length; j++) {
						var goods = cart_list[j].goods_info
						goods.forEach((item) => {
							//console.log(index)
							goodids.push(item.goods_id)
							goodsdata.push({
								id: item.goods_id,
								num: 1
							})
						})
					}
					that.gopay()
				} else if (e.value == 4) {
					this.$router.push({
						path: 'home'
					})
				} else if (e.value == 5) {
					this.delCartGoodsAll()
				}
				this.showpopup = false
			},

			delCartGoodsAll() {
				var that = this
				var popdata = this.popdata
				var ids = []
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					ids[i] = popdata.goodsReason[i].goods_id
				}
				//批量删除橘袋产品
				this.$ajax.post(this.API.api.apiurl.delCartGoodsAll, {
						cart_ids: [that.cart_id],
						// member_id: that.info.member_id,
						goods_ids: ids
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 0) {
							//console.log(data)
							if(data.data.cart_ids.length==0){
								that.$Message.info('方案内已没有补剂可以购买')
							}else{
								that.gocheckCartV2(data.data.cart_ids)
								that.delarr(ids)
							}
						} else {
							that.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			delarr(arr) {
				var that = this
				//console.log(arr)
				var cart_list = that.cart_list
				for (var i = 0; i < arr.length; i++) {
					cart_list.forEach((item, index) => {
						//console.log(index)
						if (item.goods_id == arr[i]) {
							cart_list.splice(index, 1)
						}
					})
				}
				//console.log(cart_list)
			},
			gopay() {
				var that = this
				var ids = []
				ids.push(that.count_id)
				that.$router.push({
					path: 'settlement',
					query: {
						// id: ids,
						cid: that.cid,
						type: 1
					}
				})
			},
			gocheckCartV2(cid) {
				var that = this
				//校验商品信息V2
				this.$ajax.post(this.API.api.apiurl.checkCartV2, {
						cart_ids: cid,
						member_id: that.info.member_id,
						coupon_ids: '',
						enjoy_sharing: 0,
						source: 1
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						var data = res.data.data
						var goodsdata = ''
						if (res.data.code == 0) {
							//console.log(data)
							that.checkdata = data
							that.canbuy = true
							var ids = []
							ids.push(that.count_id)
							that.$router.push({
								path: 'settlement',
								query: {
									// id: ids,
									cid: cid,
									type: 1
								}
							})
						}  else if (res.data.code == 113) {
							goodsdata = data
							that.popdata = goodsdata
							that.showpopup = true
							that.canbuy = false
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			showbuyways() {
				this.show = true
			},
			getCartOne() {
				var that = this
				//获取报告推荐产品
				this.$ajax.post(this.API.api.apiurl.getCountGoods, {
						// count_id:that.count_id,
						count_id: that.testid,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
							var data = res.data.data
							//console.log(data)
							that.reportdata = data
							var select_re = data.select_re
							var price = 0
							for (var i = 0; i < select_re.length; i++) {
								price += Number(select_re[i].re_day_use * select_re[i].shop_price)
								select_re[i].dayuseprice = Number(select_re[i].re_day_use * select_re[i].shop_price).toFixed(2)
							}
							that.price = price
							that.getbuyplans(3)
						}else if(res.data.message == '无效报告！'){
							that.$router.replace({path:'404'})
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			getbuyplans(e) {
				this.way = e
				var that = this
				var reportdata = this.reportdata
				var goods_info = reportdata.select_re
				var buyplan = this.buyplan
				//console.log(buyplan, goods_info)
				for (var i = 0; i < buyplan.length; i++) {
					buyplan[i].action = 0
					var planprice = 0
					goods_info.forEach((item) => {
						//console.log(index)
						planprice += Number(item.shop_price * item.re_day_use)
					})
					buyplan[i].dayprice = Number(planprice.toFixed(2)).toFixed(2)
					buyplan[i].lastplanprice = Number(planprice * buyplan[i].days.toFixed(2)).toFixed(2)
					buyplan[i].planprice = Number(Number(buyplan[i].discount) * planprice * buyplan[i].days.toFixed(2)).toFixed(2)
					if (buyplan[i].id == that.bid) {
						buyplan[i].action = 1
						that.action = i
						that.actionid = buyplan[i].id
					}
					if (Number(buyplan[i].price_limit) > Number(buyplan[i].dayprice)) {
						buyplan[i].canspecial = 0
					} else {
						buyplan[i].canspecial = 1
					}
				}
				//console.log(buyplan)
				that.buyplan = buyplan
			},
			thisplan(e) {
				//console.log(e)
				var buyplan = this.buyplan
				if (buyplan[e].canspecial == 0) {
					this.$Message.info('每日方案价格' + buyplan[e].price_limit + '元以上即可享受')
				} else {
					for (var i = 0; i < buyplan.length; i++) {
						buyplan[i].action = 0
					}
					buyplan[e].action = 1
					// this.actionid = buyplan[e].id
					this.bid = buyplan[e].id
					this.checked = e
					this.buyplan = buyplan
					this.$forceUpdate()
				}
			},
			thispro(e) {
				console.log(e)
			},
			ok() {
					this.actionid = this.bid
				//console.log('确认方案')
				this.action = this.checked
				this.show = false
				this.$forceUpdate()
				if (this.way == 0) {
					this.addcart()
				} else if (this.way == 1) {
					this.buythis()
				}
			},
			buythis() {
				if (this.actionid) {
					this.checkCartV2()
					// this.$router.push({ path: 'settlement'})
				} else {
					this.getbuyplans(1)
					this.show = true
				}
			},
			closepopup(){
				this.showpopup = false
			},
			checkCartV2() {
				var that = this
				if (that.actionid) {
					//添加产品，套餐，报告到新购物车
					this.$ajax.post(this.API.api.apiurl.addCart, {
							member_id: that.info.member_id,
							source: 1,
							buy_id: that.actionid,
							// count_id:that.count_id,
							count_id: that.testid,
							meal_id: '',
							meal_type: '',
							goods_id: '',
							goods_num: '',
							is_pro: 1
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							if ( res.data.code == 0) {
							var data = res.data.data
								//console.log('添加购物车成功！')
								that.cart_id = data.cart_id
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength',l+1)
								that.replaceCartGoodsNew(data.cart_id)
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					this.getbuyplans(0)
					this.show = true
				}
			},
			replaceCartGoodsNew(e) {
				//console.log(e)
				var that = this
				var goods = that.reportdata.select_re
				var arr = []
				for (var i = 0; i < goods.length; i++) {
					arr[i] = {
						goods_id: goods[i].goods_id,
						is_add: 0
					}
				}
				//批量替换橘袋[报告加入购物车时]
				this.$ajax.post(this.API.api.apiurl.replaceCartGoodsNew, {
						goods_ids: arr,
						cart_id: e,
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log('批量替换橘袋成功！')
							var ids = []
							ids.push(that.count_id)
							var cids = []
							cids.push(e)
							that.cid = cids
							// cids = JSON.stringify(cids)
							// that.$router.push({ path: 'settlement',query: {id:ids,cid:cids,type:1}})
							that.settleAccounts(cids)
							that.gocheckCartV2(cids)
						} else {
							//console.log('错误：' + res.data.message)
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			goprodetail(id){
				//console.log(id)
				this.$router.push({ path: 'product-detail',query:{
					id:id
				}})
			},
			addcart() {
				var that = this
				if (that.actionid) {
					//添加产品，套餐，报告到新购物车
					this.$ajax.post(this.API.api.apiurl.addCart, {
							member_id: that.info.member_id,
							source: 1,
							buy_id: that.actionid,
							// count_id:that.count_id,
							count_id: that.testid,
							meal_id: '',
							meal_type: '',
							goods_id: '',
							goods_num: '',
							is_pro: 1
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							if ( res.data.code == 0) {
							var data = res.data.data
								that.cart_id = data.cart_id
								that.$Message.info('添加购物车成功!');
								
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength',l+1)
							} else {
								//console.log('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					this.getbuyplans(0)
					this.show = true
				}
			},

			saveTheArrivalNoticeapi(e) {
				var that = this
				//保存到货通知消息推送任务
				this.$ajax.post(this.API.api.apiurl.saveTheArrivalNotice, {
						type: that.buytype,
						member_id: that.info.member_id,
						goods_id: e,
						meal_id: '',
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data
						if (data.code == 1) {
							//console.log(data)
						} else {
							that.$Message.info('错误：' + data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			saveTheArrivalNotice() {
				var that = this
				var popdata = this.popdata
				//console.log(popdata)
				// var saveids = []
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					// saveids[i]=popdata.goodsReason[i].goods_id
					that.saveTheArrivalNoticeapi(popdata.goodsReason[i].goods_id)
				}

				window.location.href = that.tipsurl
			},
		}
	}
</script>

<style>
	.define {
		width: 100%;
		margin: 0 auto;
		/* display: flex;
		justify-content: space-between;
		flex-wrap: wrap; */
	}

	#app .reportdefine-top {
		width: 100%;
		height: 3.89rem;
		max-width: 22.78rem;
		margin: 0 auto;
		display: flex;
		justify-content: left;
		background-image: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/detailbg.png);
		background-repeat: no-repeat;
		background-position: top right;
		background-size: 5.83rem 3.89rem;
	}

	.reportdefine-top .define-title {
		width: auto;
		height: 1.6rem;
		margin: 0.87rem 0 0.91rem 0;
		text-align: left;
	}

	.define-title .define-name {
		line-height: 0.83rem;
		height: 0.83rem;
		font-weight: bold;
		font-size: 0.59rem;
		display: flex;
	}

	.define-title .define-msg {
		height: 0.61rem;
		line-height: 0.61rem;
		font-size: 0.44rem;
		color: #1A1A1A;
	}

	.define-pros {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		max-width: 22.78rem;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		margin: 0 auto;
		padding: 0.59rem 0.59rem 0 0.59rem;
		margin-top: -0.5rem;
		margin-bottom: 4.2rem;
		/* z-index: 5; */
		background: #ffffff;
	}

	.pro-box {
		/* border: 0.01rem solid #eee; */
		display: flex;
		width: 48%;
		max-width: 8rem;
		margin-bottom: 1.19rem;
		position: relative;
	}

	.zhao {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(255, 255, 255, 0.4);
	}

	.pro-box .pro-img {
		width: 1.6rem;
		height: 1.6rem;
		margin: 0.69rem 0;
		/* border: 0.01rem solid #eee; */
	}

	.pro-img img {
		width: 100%;
		height: 100%;
	}

	.pro-box .pro-name {
		color: #1A1A1A;
		font-size: 0.44rem;
		height: 0.59rem;
		line-height: 0.59rem;
		text-align: left;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		/* max-width: 6rem; */
	}

	.pro-box .pro-name span {
		width: auto;
		height: 0.59rem;
		line-height: 0.59rem;
		background: #ffffff;
		font-size: 0.37rem;
		display: inline-block;
		padding: 0 0.1rem;
	}

	.pro-box .pro-name .lspan {
		width: auto;
		height: 0.59rem;
		line-height: 0.59rem;
		background: #EFDDC8;
		font-size: 0.33rem;
		/* display: inline-block; */
		padding: 0 0.1rem;
		border-radius: 0.1rem;
		/* margin-right: 0.22rem; */
	}

	.pro-box .pro-name .spantxt {
		width: auto;
	}

	.pro-box .pro-msg {
		font-size: 0.37rem;
		height: 0.56rem;
		line-height: 0.56rem;
		color: #666666;
		text-align: left;
		margin-top: 0.3rem;
		/* overflow: hidden; */
		/* max-width: 6rem; */
	}

	.pro-box .pro-num {
		height: 0.52rem;
		color: #1A1A1A;
		font-size: 0.37rem;
		text-align: left;
		margin-top: 1rem;
		padding-right: 0.1rem;
		/* max-width: 6rem; */
		display: flex;
		justify-content: space-between
	}

	.pro-num .rnum {
		margin-left: 0.59rem;
	}

	.tips-msgs {
		width: 100%;
		height: auto;
		line-height: 0.59rem;
		text-align: center;
		font-size: 0.26rem;
		color: #898989;
		margin: 0.59rem auto;
		padding: 0 30%;
	}

	.buy-box {
		/* width: 100%; */
		height: 2.07rem;
		background: #fff;
		box-shadow: 0rem -0.07rem 0.22rem 0rem rgba(0, 0, 0, 0.1);
		position: fixed;
		bottom: 0;
		left: 0;
	}

	.buy-num {
		width: calc(100% - 0.56rem);
		height: 1.41rem;
		margin: 0 auto;
		background: #F9FAFB;
		border-radius: 0.37rem 0.37rem 0rem 0rem;
		position: fixed;
		bottom: 2.07rem;
		left: 0.28rem;
		z-index: 1;
	}

	.main-weight {
		width: 100%;
		max-width: 18.5rem;
		height: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.buy-way {
		width: 3.37rem;
		height: 0.81rem;
		line-height: 0.81rem;
		background: #ffffff;
		border-radius: 0.19rem;
		border: 0.02rem solid #C25E4D;
		margin: 0.3rem 0;
		color: #C25E4D;
		font-size: 0.37rem;
		cursor: pointer;
	}

	.price {
		width: auto;
		height: 0.83rem;
		color: #1a1a1a;
		font-size: 0.37rem;
		line-height: 0.83rem;
		font-weight: bold;
		margin: 0.29rem 0;
	}

	.price span {
		font-size: 0.2rem;
	}

	.day-price {
		height: 1.17rem;
		margin-top: 0.65rem;
		color: #1A1A1A;
		text-align: left;
	}

	.day-price .day-num {
		font-size: 0.44rem;
		font-weight: bold;
	}

	.day-num span {
		color: #C25E4D;
	}

	.day-price .day-money {
		font-size: 0.3rem;
		margin-top: 0.15rem;
	}

	.actions-btns {
		width: auto;
		height: 1.26rem;
		margin: 0.59rem 0;
		display: flex;
	}

	.actions-btns div {
		width: 4.44rem;
		height: 1.26rem;
		border-radius: 1.26rem;
		margin-right: 0.56rem;
		line-height: 1.26rem;
		text-align: center;
		font-size: 0.44rem;
		background: #FFFFFF;
		border: 0.04rem solid #000000;
		color: #1A1A1A;
		cursor: pointer;
	}

	.actions-btns .buythis {
		margin-right: 0;
		background: #C25E4D;
		color: #ffffff;
		border: none;
	}

	.choseway {
		width: 100%;
		height: 100%;
		background: #000000;
		position: fixed;
		left: 0;
		top: 0;
	}

	.chose-box {
		width: 100%;
		height: auto;
		max-width: 12.78rem;
		margin: 0 auto;
		background: #ffffff;
		border-radius: 0.19rem;
		margin-top: 0.74rem;
		padding: 1.19rem 0.83rem;
	}

	.chose-box .chose-title {
		width: 100%;
		height: 0.83rem;
		display: flex;
		justify-content: left;
		line-height: 0.83rem;
		font-weight: bold;
		font-size: 0.59rem;
	}

	.chose-title img {
		width: 0.64rem;
		height: 0.64rem;
		margin: 0.095rem 0.29rem 0.095rem 0.095rem;
		cursor: pointer;
	}

	.buy-btn {
		width: 100%;
		height: 1.8rem;
		line-height: 0.62rem;
		display: flex;
		justify-content: space-between;
		padding: 0.59rem;
		font-size: 0.44rem;
		font-weight: bold;
		border: 0.04rem solid #CCCCCC;
		border-radius: 0.19rem;
	}

	.action {
		border: 0.04rem solid #C25E4D;
	}

	.ok {
		width: 6.11rem;
		height: 1.33rem;
		line-height: 1.33rem;
		text-align: center;
		color: #ffffff;
		font-size: 0.52rem;
		background: #C25E4D;
		border-radius: 0.67rem;
		margin: 0.5rem auto 0 auto;
		cursor: pointer;
	}

	.chose-price {
		color: #C25E4D;
	}

	.theprice {
		position: relative;
	}

	.tips {
		position: absolute;
		width: 3.24rem;
		height: 1.1rem;
		line-height: 1.1rem;
		background: #C25E4D;
		font-size: 0.41rem;
		color: #ffffff;
		font-weight: 500;
		top: -1.69rem;
		border-radius: 0.19rem;
	}

	.lastprice {
		color: #cccccc;
		font-size: 0.37rem;
		text-decoration: line-through;
		margin-left: 0.3rem;
	}

	.experience {
		margin-top: 1.43rem;
	}

	.onemonth {
		margin-top: 1.57rem;
	}

	.twomonth {
		margin-top: 0.89rem;
		margin-bottom: 1.19rem;
	}

	.tipbottom {
		width: 0;
		height: 0;
		border-left: 0.15rem solid transparent;
		border-right: 0.15rem solid transparent;
		border-top: 0.15rem solid #C25E4D;
		margin-left: 0.5rem;
	}

	.tips2 {
		width: 1.72rem;
	}

	.tips3 {
		display: none;
	}

	.choseimg {
		width: 0.6rem;
		height: 0.6rem;
		margin: 0.1rem 0;
	}

	.nonum {
		width: auto;
		color: #C25E4D;
	}

	.pro-infobox {
		width: calc(100% - 1.6rem);
		position: relative;
	}
	.rgba{
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.7);
		z-index: 11;
	}
	.closepopup{
		width: 0.59rem;
		height: 0.59rem;
		position: fixed;
		top: calc( 50% + 6.26rem );
		left: calc( 50% - 0.295rem );
		z-index: 11;
	}
	.closepopup img{
		width: 100%;
		height: 100%;
	}
	.pro-name-box{
		display: flex;
	}
	@media screen and (min-width:1001px) {
		.pro-box .pro-name .lspan{
			height: 0.59rem;
			line-height: 0.59rem;
			font-size: 0.28rem;
			padding: 0 0.15rem;
		}
		.pro-box .pro-name{
			height: 0.59rem;
			line-height: 0.59rem;
			font-size: 0.3rem;
		}
		.pro-box .pro-name span{
			height: 0.59rem;
			line-height: 0.59rem;
			font-size: 0.37rem;
		}
		.pro-box .pro-name .rnum{
			/* font-weight: 400; */
			font-size: 0.32rem;
		}
		.pro-box .pro-msg{
			font-size: 0.34rem;
		}
		.pro-box .pro-num{
			margin-top: 0.6rem;
		}
		.pro-infobox{
			/* border-bottom: 0.02rem solid #eee; */
		}
		.pro-box .pro-img{
			margin: 0.49rem 0;
		}
		.pro-box{
			max-width:none;
			width: 50%;
			border-bottom: 0.02rem solid #eee;
			padding-bottom: 1rem;
		}
		.noborderb{
			border: none;
		}
		.zuo{
			padding-right: 4%;
		}
		.you{
			padding-left: 4%;
		}
	}
	@media screen and (max-width:1000px) {
		.main-weight {
			padding: 0 0.3rem;
		}


		.define-pros {
			width: 94%;
			padding: 0.59rem 0.19rem;
		}

		.tips-msgs {
			width: 100%;
			padding: 0;
			margin: 0.59rem auto;
		}

		.pro-box {
			margin-bottom: 1.19rem;
		}
		.pro-box {
			width: 100%;
			max-width: none;
		}
		.pro-box .pro-name{
			position: relative;
			max-width: none;
		}
		.pro-name .rnum{
			position: absolute;
			right: 0;
			font-weight: 300;
		}
		
		.define .actions-btns{
			height: 1.11rem;
			margin: 0.48rem 0;
		}
		.define .day-price{
			margin-top: 0.45rem;
		}
		.define .main-weight{
			padding: 0 0.45rem;
		}
		.buy-num{
			/* width:calc(100% - 0.94rem) */
		}
		.reportdefine-top .define-title {
			width: calc( 100% - 0.6rem );
			text-align: left;
			height: auto;
			margin: 0.59rem auto;
		}
		.reportdefine-top{
			height: auto;
			margin-bottom:0.59rem;
		}
		
		.define-title .define-name{
			height: auto;
			/* width: 50%; */
			display: block;
			font-size: 0.59rem;
		}
		.define-title .define-msg{
			margin-top: 0.2rem;
			font-size: 0.37rem;
		}
		.pro-infobox{
			width: calc( 100% - 1.6rem );
		}
	}

	@media screen and (max-width:720px) {
		.chose-box {
			width: 94%;
		}
	}
	@media screen and (max-width:1000px) {
		.pro-box .pro-img{
			width: 1.6rem;
			height: 1.6rem;
			margin: 0.69rem 0;
		}
		.pro-box .pro-name span{
			font-size: 0.38rem;
		}
		.pro-boxline{
			position: absolute;
			width: 100%;
			bottom: -0.59rem;
			left: 0;
			border-bottom: 0.02rem solid #E6E6E6;
		}
		.nomargin{
			margin-bottom: 0;
		}
	}
</style>
