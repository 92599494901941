<template>
	<div class="cart main-page">
		<div class="page-bg"></div>
		<div class="cart-title">
			购物车
		</div>
		<div class="cart-list">
			<div class="nocart-box" v-if="cart_list.length==0">
				<div class="nocart-title">什么都没有</div>
				<div class="nocart-txt">你还没有任何橘袋哦~</div>
				<div class="nocart-btn public-btn cursor" @click="godingzhi">即刻定制</div>
			</div>
			<div class="cart-box" v-for="(item,index) in cart_list" :key="index">
				<div class="sale_status" v-if="item.sale_status==1">
					失效
				</div>
				<div class="input cursor" v-if="item.sale_status==0">
					<input class="cursor" type="checkbox" @click="checkit(index)" :checked="item.checked" />
					<!-- <Radio v-model="item.checked" :border="false"></Radio> -->
				</div>
				<div class="input cursor" v-if="item.sale_status==1">
					<input class="cursor" type="checkbox" disabled="disabled" @click="checkit(index)" style="border: 0.02rem solid #eeeeee;" :checked="item.checked" />
					<!-- <Radio v-model="item.checked" :border="false"></Radio> -->
				</div>
				<div class="cart-img">
					<img :src="item.img" alt="">
				</div>
				<div class="cart-txt" :class="item.sale_status==1?'nosale':''">
					<div class="">
						<div class="cart-name">{{item.name}}</div>
						<div class="cart-price"><span>￥</span>{{ item.total_price }}<del class="deltxtsss" v-if="item.total_price!=item.original_market_price">￥{{ item.original_market_price }}</del></div>
					</div>
					<div class="cart-way cursor" v-if="item.source==1&&item.sale_status==0">
						<div class="div" @click="showthislist(item,index)">{{item.plan_title}} ({{item.buy_days}}天) <img class="pcimg"
							src="../../public/imgs/zhankai.png" alt=""><img class="phoneimg" src="../../public/imgs/chevron-down.png" alt=""></div>

						<div class="showwaymenu" v-if="showlist==index">
							<div class="way-line" :class="items.dayprice<items.price_limit?'cannouse':''" v-for="(items,j) in thisbuyplan"
							:key="j" @click="updateBuyPlan(items,item.id,index)">
								<div class="way-name">{{items.title}} ({{items.days}}天)</div>
								<div class="way-price"><span v-if="items.discountprice!=items.totalprice">￥{{(items.totalprice).toFixed(2)}}</span>￥{{(items.discountprice).toFixed(2)}}
									<div class="way-tips" v-if="items.is_conflict&&items.dayprice>=items.price_limit&&items.plan_brief">
										<div class="tips-div"></div>{{items.plan_brief}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="cart-way meal_num_box cursor" v-if="item.source==2&&item.sale_status==0">
						<div class="meal_num">
							<div class="reduceone">
								<img v-if="item.num>1" @click="dayi(item,item.id,index)" src="../../public/imgs/pro/jian.png" alt="">
								<img v-if="item.num==1" @click="dengyi(0)" src="../../public/imgs/pro/jian2.png" alt="">
							</div>
							<div class="mealnum">{{item.num}}</div>
							<div class="addone">
								<img v-if="item.num<99" @click="addone(item,item.id,index)" src="../../public/imgs/pro/jia.png" alt="">
								<img v-if="item.num==99" @click="dengyi(1)" src="../../public/imgs/pro/jia2.png" alt="">
							</div>
						</div>
					</div>
				</div>
				<div class="del cursor" @click="delthis(item.id,index)">
					<img src="../../public/imgs/del_cart.png" alt="">
					<div class="deltxt">删除</div>
				</div>
			</div>
			<div class="bottommsg" v-if="cart_list.length>0">* 应跨境电商政策要求，每笔订单不能超过5000元</div>
		</div>
		<div class="paycart-btn">
			<div class="cart-bottom">
				<div class="paycart-txt" :class="yuan&&yuan>totalprice?'youyuan':''">
					<del class="deltxts" v-if="yuan&&yuan>totalprice">￥{{yuan}}</del>
					<div class="paycart-price">合计 <span>￥{{totalprice}}</span></div>
					<!-- <div class="paycart-msg">保税区直邮</div> -->
				</div>
				<a class="paycart public-btn cursor" @click="gopay">结 算</a>
			</div>
		</div>
		<Modal v-model="model9" width="320" :closable="false" :scrollable="false">
			<div style="text-align:center">
				<p class="modelname">订单原价超出限额</p>
				<p class="modeltxt">根据海关总署、国家税务总局《关于跨境电子商务零售进口税收政策的通知》，自2019年1月1日起，个人单笔交易限值人民币5000元，个人年度交易限值人民币26000元。</p>
			</div>
			<div>
				<div class="modelbtn cursor" @click="noshowmodel">确定</div>
			</div>
		</Modal>
		<Modal v-model="modaldel" width="423.9" :styles="{top: '30%'}" :scrollable="true">
			<p class="modaltitle">
				<Icon type="ios-information-circle" color="#faad14" size="24"></Icon>
				<span>删除确定</span>
			</p>
			<div class="modalmain" style="text-align:center">
				<p>确认删除该橘袋吗？</p>
			</div>
			<div class="modalbtns">
				<div class="modalcancel modalbtn cursor" @click="noshowit">取消</div>
				<div class="modalok modalbtn cursor" @click="okdel">确定</div>
			</div>
			<div class="disfoot" slot="footer">
				<!-- <div class="modelbtn cursor" @click="okdel">确认删除</div> -->
			</div>
		</Modal>
		<!-- <popup v-if="showpopup" :rdata="popdata" @getback="getbackinfo"></popup> -->
		<div class="rgba" @click="closepopup" v-if="showpopup"></div>
		<popup v-if="showpopup" :rdata="popdata" @getback="getbackinfo"></popup>
		<div class="closepopup" v-if="showpopup" @click="closepopup">
			<img src="../../public/imgs/no.png" alt="">
		</div>
	</div>
</template>

<script>
	import popup from '../components/popup.vue'
	export default {
		name: 'cart',
		components: {
			popup
		},
		data: () => ({
			showmenu: false,
			showpopup: false,
			zu:0,
			checked: true,
			showlist: -1,
			model9:false,
			thisbuyplan: '',
			popdata: '',
			buyplan: '',
			tipsurl: 'https://mmbizurl.cn/s/yiDciNLJd',
			info: '',
			modaldel:false,
			totalprice: 0,
			yuan:'',
			cart_list: [],
			checkedIds:[]
		}),
		mounted() {
			var that = this
			var info = JSON.parse(localStorage.getItem('info'))
			if (!info) {
				this.$Message.info('请先登录');
				this.$router.replace({
					path: 'login?way=cart'
				})
			} else {
				that.info = info
				this.getBuyPlanV2()
				this.$ajax.post(this.API.api.apiurl.getCartList, {
						member_id: info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data.data
						var clist = []
						var nlist = []
						if (res.data.code == 0) {
							var cartlist = data
							for (var i = 0; i < cartlist.length; i++) {
								cartlist[i].checked = false
								if (cartlist[i].source == 1||cartlist[i].source == 2) {
									if(cartlist[i].sale_status==1){
										nlist.push(cartlist[i])
									}else{
										clist.push(cartlist[i])
									}
								}
							}
							clist = clist.concat(nlist);
							that.cart_list = clist
							that.chatiyan()
							var ll = 0
							clist.forEach((item)=>{
								ll +=item.num
							})
							//console.log(ll)
							localStorage.setItem('clength', ll)
							// //console.log(cartlist)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			}
		},
		methods: {
			chatiyan(){
				var that = this
				var clist = this.cart_list
				for(var j=0;j<clist.length;j++){
					if(clist[j].source==1&&clist[j].buy_id==14){
						var id = clist[j].id
						var thelist = clist[j].goods_info
						var dp = 0
						thelist.forEach((item) => {
							// //console.log(index)
							dp += Number((Number(item.goods_price)*item.goods_num).toFixed(2))
						})
						if(dp<9){
							that.notiyan = true
							//console.log(that.buyplan[1],clist[j])
								var e = that.buyplan[1]
								e.totalprice = 0
								e.dayprice = 0
								e.discountprice = 0
								var goods = clist[j].goods_info
								goods.forEach((item) => {
									// //console.log(index)
									e.dayprice += Number((Number(item.goods_price) * item.goods_num).toFixed(2))
									e.totalprice += Number((Number(item.goods_price) * item.goods_num * e.days).toFixed(2))
								})
								e.totalprice = Number((e.totalprice).toFixed(2))
								e.discountprice = Number(Number(Number(e.discount) * e.totalprice).toFixed(2))
								// //console.log(buyplan[i].price_limit>buyplan[i].dayprice)
								e.canbuy = true
								that.updateBuyPlan(e,id, j)
						}
					}
				}
			},
			newlist(){
				var that = this
				this.$ajax.post(this.API.api.apiurl.getCartList, {
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						console.log('newlist',res)
						var data = res.data.data
						var clist = []

						// var data = res.data.data
						// var clist = []
						var nlist = []
						if (res.data.code == 0) {
							var cartlist = data
							for (var i = 0; i < cartlist.length; i++) {
								if(this.checkedIds.includes(cartlist[i].id)) {
									cartlist[i].checked = true
								}
								if (cartlist[i].source == 1||cartlist[i].source == 2) {
									if(cartlist[i].sale_status==1){
										nlist.push(cartlist[i])
									}else{
										clist.push(cartlist[i])
									}
								}
							}
							clist = clist.concat(nlist);
							that.cart_list = clist
							that.chatiyan()
							var ll = 0
							clist.forEach((item)=>{
								ll +=item.num
							})
							//console.log(ll)
							localStorage.setItem('clength', ll)
							// //console.log(cartlist)
							that.gettotalprice()
						} else {
							that.$Message.info('错误：' + res.data.message)
						}

					})
					.catch(error => {
						console.log(error)
					})
			},
			dayi(item,id,index){
				var that = this
				//console.log(item,id,index)
				var num = item.num-1
				this.$ajax.post(this.API.api.apiurl.editCartNum, {
						cart_id: id,
						num:num
					},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						var cartlist = []
						if (res.data.code == 0) {
							//console.log(data)
							cartlist = that.cart_list
							cartlist[index].num = num
							that.cart_list = cartlist
							that.newlist()
							// that.gettotalprice()
						} else if(res.data.code == 117){
							//console.log(data)
							cartlist = that.cart_list
							cartlist[index].num = num
							that.cart_list = cartlist
							that.newlist()
							// that.gettotalprice()
						}else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			dengyi(e){
				if(e==0){
					this.$Message.info('至少购买一件喔')
				}else{
					this.$Message.info('最多购买九十九件喔')
				}
				//console.log('等于一',e)
			},
			addone(item,id,index){
				var that = this
				var num = item.num+1
				//console.log(item,id,index)
				this.$ajax.post(this.API.api.apiurl.editCartNum, {
						cart_id: id,
						num:num
					},{
							headers:{
								'Authorization':'Bearer '+that.info.token,
							}
						})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log(data)
							var cartlist = that.cart_list
							cartlist[index].num = num
							that.cart_list = cartlist
							that.newlist()
								// that.gettotalprice()
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			noshowit(){
				this.modaldel = false
			},
			getBuyPlanV2() {
				// 获取购买方案/
				var that = this
				this.$ajax.post(this.API.api.apiurl.getBuyPlanV2, {
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						if (res.data.code == 0) {
							var data = res.data.data
							//console.log(data)
							that.buyplan = data
						} else {
							//console.log('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			closepopup() {
				this.showpopup = false
			},
			getbuyplan(e) {
				console.log(e)
			},
			updateBuyPlan(e, id, index) {
				console.log(index)
				//console.log(e.thumb_url)
				// var theimg = e.thumb_url
				var that = this
				if (e.canbuy) {
					//修改购买方案
					this.$ajax.post(this.API.api.apiurl.updateBuyPlan, {
							cart_id: id,
							buy_id: e.id,
							member_id: that.info.member_id,
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							that.showlist = -1
							if (res.data.code == 0) {
								// var clist = that.cart_list
								that.newlist()
								// that.gettotalprice()
								//console.log(clist[index])
								// clist[index].plan_title = e.title
								// clist[index].buy_days = e.days
								// clist[index].img = theimg
								// clist[index].original_price = Number((e.totalprice).toFixed(2))
								// clist[index].discountprice = Number((e.totalprice).toFixed(2))
								// clist[index].total_price = Number((e.discountprice).toFixed(2))
								// that.cart_list = clist
								// that.gettotalprice()
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				} else {
					this.$Message.info('每日方案价格' + e.price_limit + '元以上即可享受');
				}
			},
			okdel(){
				this.modaldel = false
				var id= this.delid
				var index = this.delindex
				this.showlist = -1
				var that = this
				this.$ajax.post(this.API.api.apiurl.delCart, {
						cart_ids: [id],
						member_id: that.info.member_id,
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							// //console.log(data)
							var cartlist = that.cart_list
							var ll = cartlist[index].num
							// for(var i= 0;i<cartlist.length;i++){
							cartlist.splice(index, 1)
							// }
							that.cart_list = cartlist
							var l = Number(localStorage.getItem('clength'))
							localStorage.setItem('clength', l - ll)
							that.gettotalprice()
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			delthis(id, index) {
				this.delid = id
				this.delindex=index
				this.modaldel = true
			},
			godefine(id, bid) {
				var that = this
				that.$router.push({
					path: 'define',
					query: {
						id: id,
						bid: bid
					}
				})
			},
			gettotalprice() {
				var list = this.cart_list
				var totalprice = 0
				var yuan = 0
				console.log('gettotalprice')
				// //console.log(list)
				for (var i = 0; i < list.length; i++) {
					if (list[i].checked == true) {
						totalprice += Number(list[i].total_price)
						yuan += Number(list[i].original_price)
					}
				}
				//console.log(totalprice,yuan)
				this.yuan = Number(Number(yuan).toFixed(2))
				this.totalprice = Number(Number(totalprice).toFixed(2))
			},
			goqus() {
				this.$router.push({
					path: 'questionnaire'
				})
			},
			showthislist(e, index) {
				//console.log(e)
				var goods = e.goods_info
				var buyplan = this.buyplan
				for (var i = 0; i < buyplan.length; i++) {
					buyplan[i].totalprice = 0
					buyplan[i].dayprice = 0
					buyplan[i].discountprice = 0
					goods.forEach((item) => {
						// //console.log(index)
						buyplan[i].dayprice += Number((Number(item.goods_price) * item.goods_num).toFixed(2))
						buyplan[i].totalprice += Number((Number(item.goods_price) * item.goods_num * buyplan[i].days).toFixed(2))
					})
					buyplan[i].totalprice = Number((buyplan[i].totalprice).toFixed(2))
					buyplan[i].discountprice = Number(Number(Number(buyplan[i].discount) * buyplan[i].totalprice).toFixed(2))
					// //console.log(buyplan[i].price_limit>buyplan[i].dayprice)
					buyplan[i].canbuy = buyplan[i].price_limit <= buyplan[i].dayprice
				}
				//console.log(buyplan)
				this.thisbuyplan = buyplan
				if (this.showlist == index) {
					this.showlist = -1
				} else {
					this.showlist = index
				}
				this.$forceUpdate()
			},
			checkit(index) {
				let checkedIds = this.checkedIds
				var cartlist = this.cart_list
				cartlist[index].checked = !cartlist[index].checked
				if(cartlist[index].checked) {
					checkedIds.push(cartlist[index].id)
				} else {
					checkedIds.splice(checkedIds.findIndex(item => item === cartlist[index].id), 1)
				}

				this.checkedIds = checkedIds
				console.log(this.checkedIds)
				this.cart_list = cartlist
				this.$forceUpdate()
				this.gettotalprice()
			},
			gopay() {
				var totalprice = this.yuan
				if (totalprice > 5000) {
					this.model9 = true
					document.body.style.overflow = 'hidden';
					// this.$Notice.warning('根据海关总署、国家税务总局《关于跨境电子商务零售进口税收政策的通知》，自2019年1月1日起，个人单笔交易限值人民币5000元，个人年度交易限值人民币26000元。')
				} else {
					var that = this
					var clist = this.cart_list
					var ids = []
					var lll = []
					var cids = []
					var zu = 0
					var gnum = 0
					for (var i = 0; i < clist.length; i++) {
						if (clist[i].checked == true) {
							cids.push(clist[i].id)
							lll.push(clist[i])
							gnum = clist[i].buy_id == 14 ? gnum + 1 : gnum
						}
					}
					if(lll.length>1){
						for(var j=0;j<lll.length;j++){
							if(lll[j].source==2){
									that.zu = 1
									zu =1
							}
							if(lll[j+1]){
								if(lll[j].source!=lll[j+1].source){
									that.zu = 1
									zu =1
								}
							}
						}
					}else if(lll.length==1){
						if(lll[0].source==2){
							that.zu = 1
									zu =1
						}
					}
					//console.log(lll,zu)
					//console.log(cids, gnum)
					// if (gnum > 1) {
					// 	that.$Message.info('体验装超过可购买的个数')
					// } else {
					if (cids.length == 0) {
						that.$Message.info('没有可结算商品')
					} else {
						//校验商品信息V2
						this.$ajax.post(this.API.api.apiurl.checkCartV2, {
								cart_ids: cids,
								member_id: that.info.member_id,
								coupon_ids: '',
								enjoy_sharing: 0,
								source: 3
							},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
							.then(res => {
								////console.log(res)
								var data = res.data.data
								data.zu = zu
								var goodsdata = ''
								if (res.data.code == 0) {
									//console.log(data)
									that.checkdata = data
									that.canbuy = true
									that.$router.push({
										path: 'settlement',
										query: {
											id: ids,
											cid: cids,
											type: 3
										}
									})
								} else if (res.data.code == 113) {
									goodsdata = data
									that.popdata = goodsdata
									that.showpopup = true
									that.canbuy = false
								} else {
									that.$Message.info('错误：' + res.data.message)
								}
							})
							.catch(error => {
								console.log(error)
							})
					}
					// }
				}
			},
			gopays() {
				var that = this
				var ids = []
				var clist = this.cart_list
				var cids = []
				for (var i = 0; i < clist.length; i++) {
					if (clist[i].checked == true) {
						cids.push(clist[i].id)
					}
				}
				// ids.push(that.count_id)
				that.$router.push({
					path: 'settlement',
					query: {
						id: ids,
						cid: cids,
						type: 3
					}
				})
			},

			delCartGoodsAll() {
				var that = this
				var popdata = this.popdata
				var clist = this.cart_list
				var ids = []
				var cids = []
				for (var ip = 0; ip < clist.length; ip++) {
					if (clist[ip].checked == true) {
						cids.push(clist[ip].id)
					}
				}
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					ids[i] = popdata.goodsReason[i].goods_id
				}
				//批量删除橘袋产品
				this.$ajax.post(this.API.api.apiurl.delCartGoodsAll, {
						cart_ids: cids,
						member_id: that.info.member_id,
						goods_ids: ids
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data.data
						if (res.data.code == 0) {
							//console.log(data)
							if (data.cart_ids.length == 0) {
								that.$Message.info('方案内已没有补剂可以购买')
							} else {
								that.gocheckCartV2(data.cart_ids)

								that.delarr(ids)
							}
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			noshowmodel(){
				this.model9 = false
				document.body.style.overflow = 'auto';
			},
			gocheckCartV2(cid) {
				var that = this
				//校验商品信息V2
				this.$ajax.post(this.API.api.apiurl.checkCartV2, {
						cart_ids: cid,
						member_id: that.info.member_id,
						coupon_ids: '',
						enjoy_sharing: 0,
						source: 3
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						var data = res.data.data
								data.zu = that.zu
						var goodsdata = ''
						if (res.data.code == 0) {
							//console.log(data)
							that.checkdata = data
							that.canbuy = true
							var ids = []
							ids.push(that.count_id)
							that.$router.push({
								path: 'settlement',
								query: {
									id: ids,
									cid: cid,
									type: 3
								}
							})
						} else if (res.data.code == 113) {
							goodsdata = data
							that.popdata = goodsdata
							that.showpopup = true
							that.canbuy = false
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			godingzhi(){
				this.$router.push({path: 'home'})
			},
			saveTheArrivalNoticeapi(e) {
				var that = this
				//保存到货通知消息推送任务
				this.$ajax.post(this.API.api.apiurl.saveTheArrivalNotice, {
						// type: that.buytype,
						type: 1,
						member_id: that.info.member_id,
						ids: e,
						meal_id: '',
					},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
					.then(res => {
						////console.log(res)
						// var data = res.data.data
						if (res.data.code == 0) {
							//console.log(data)
						} else {
							that.$Message.info('错误：' + res.data.message)
						}
					})
					.catch(error => {
						console.log(error)
					})
			},
			saveTheArrivalNotice() {
				var that = this
				var popdata = this.popdata
				//console.log(popdata)
				var saveids = []
				for (var i = 0; i < popdata.goodsReason.length; i++) {
					saveids[i]=popdata.goodsReason[i].goods_id
				}
					that.saveTheArrivalNoticeapi(saveids)

				window.location.href = that.tipsurl
			},
			gothisreport() {
				var that = this
				//console.log(this.cart_list)
				//console.log(this.popdata)
				var id = this.popdata.goodsReason[0].goods_id
				var list = this.cart_list
				for (var i = 0; i < list.length; i++) {
					var goods = list[i].goods_info
					goods.forEach((item) => {
						if (item.goods_id == id) {
							that.$router.push({
								path: 'report?id=' + list[i].count_id + '&way=list'
							})
						}
					})
				}
			},
			getbackinfo(e) {
				//console.log(e)
				if (e.value == 1) {
					this.gopays()
				} else if (e.value == 0) {
					this.saveTheArrivalNotice()
					// window.location.href = 'https://mp.weixin.qq.com/s?__biz=MzU4NTc2OTQxMA==&mid=100004266&idx=1&sn=b4b6aeb22c2375391eda621cb0899cf2&chksm=7d84d9074af3501188729533d69130a12eeb15de1ff0ecea4160e403de5896fa233fc588eb29#rd'
				} else if (e.value == 3) {
					this.gopays()
				} else if (e.value == 4) {
					this.$router.push({
						path: 'home'
					})
				} else if (e.value == 5) {
					this.delCartGoodsAll()
				} else if (e.value == 9) {
					this.gothisreport()
				}else if (e.value == 111) {
					//console.log('111')
				}
				this.showpopup = false
			}
		}
	}
</script>

<style>
	.cart {
		width: 100%;
		height: auto;
		max-width: 22.87rem;
		margin: 0 auto;
		margin-top: 0.87rem;
		padding-bottom: 3rem;
	}

	.page-bg {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: #efefef;
	}

	.cart-title {
		width: 100%;
		height: 0.83rem;
		line-height: 0.83rem;
		font-size: 0.59rem;
		font-weight: bold;
		margin: 0.87rem 0 0.72rem 0;
		color: #1A1A1A;
		text-align: left;
		/* text-indent: 0.59rem; */
	}

	.cart-list {
		width: 100%;
		height: auto;
		padding: 0.01rem 0 0.89rem 0;
		/* background: #FFFFFF; */
		border-radius: 0.19rem;
	}

	.cart-box {
		width: 100%;
		height: auto;
		display: flex;
		margin-top: 0.59rem;
		position: relative;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		padding: 0.46rem 0.59rem;
		/* justify-content: space-between; */
	}
	.sale_status{
		position: absolute;
		top: 0;
		right: 0;
		background: #c8d0d8;
		width: 1.6rem;
		line-height: 0.71rem;
		border-radius: 0 0.19rem 0 0.19rem;;
	}
	.nocart-box{
		width: 100%;
		height: auto;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		padding: 0.59rem 0;
		border-radius: 0.19rem;
		background: #FFFFFF;
	}
	.nocart-title{
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.59rem;
		font-weight: bold;
		color: #1A1A1A;
		text-align: center;
	}
	.nocart-txt{
		width: 100%;
		height: auto;
		line-height: 0.51rem;
		font-size: 0.3rem;
		color: #999999;
		text-align: center;
		margin: 0.5rem 0;
	}
	.nocart-btn{
		margin: 0 auto;
	}

	.cart-box .input {
		width: 0.44rem;
		height: 0.44rem;
		/* -webkit-appearance: checkbox!important; */
		margin-top: 0.89rem;
		border-radius: 0.44rem;
		/* margin-right: 0.3rem; */
	}

	.cart-box input[type='checkbox'] {
		width: 0.44rem;
		height: 0.44rem;
		background-color: #fff;
		-webkit-appearance: none !important;
		border: 0.02rem solid #c9c9c9;
		border-radius: 0.44rem;
		outline: none;
	}

	.cart-box input:checked {
		background: url("../../public/imgs/checked.png")no-repeat center;
		background-size: 0.8rem 0.8rem;
	}

	.cart-box .cart-img {
		width: 2.22rem;
		height: 2.22rem;
		background: #FB9494;
		border-radius: 0.19rem;
		margin: 0 0.3rem;
		/* margin-left: 0.59rem; */
	}

	.cart-img img {
		width: 2.22rem;
		height: 2.22rem;
		border-radius: 0.19rem;
	}

	.cart-box .cart-txt {
		width: 10.9rem;
		/* margin-left: 0.3rem; */
		display: flex;
		justify-content: space-between;
	}

	.cart-box .cart-name {
		font-size: 0.44rem;
		color: #1A1A1A;
		/* margin-top: 0.82rem; */
		line-height: 0.61rem;
		font-weight: bold;
		text-align: left;
	}

	.cart-box .cart-price {
		font-size: 0.44rem;
		color: #1A1A1A;
		margin-top: 0.7rem;
		font-weight: bold;
		height: 0.61rem;
		line-height: 0.61rem;
		text-align: left;
	}

	.cart-price span {
		font-size: 0.37rem;
	}
	.nosale .cart-name{
		color: #cccccc;
	}
	.nosale .cart-price{
		color: #cccccc;
	}

	.cart-way {
		width: 2.85rem;
		height: 1.11rem;
		line-height: 1.11rem;
		font-size: 0.3rem;
		margin-top: 0.56rem;
		border-radius: 0.19rem;
		border: 0.04rem solid #C25E4D;
		color: #C25E4D;
		position: relative;
	}
	.meal_num_box{
		border: none;
	}
	.meal_num_box .meal_num{
		display: flex;
		justify-content: space-between;
		color: #1A1A1A;
	}
	.meal_num div{
		width: 0.85rem;
	}
	.meal_num div img{
		width: 0.55rem;
		height: 0.55rem;
		margin: 0.15rem;
	}

	.cart-way .div {
		width: 100%;
		/* height: 0.81rem;
		line-height: 0.81rem; */
		display: flex;
		justify-content: center;
		/* padding: 0 0.3rem; */
	}

	.cart-way .div img {
		width: 0.4rem;
		height: 0.4rem;
		margin: 0.35rem 0;
		margin-left: 0.2rem;
	}

	.cart-way .showwaymenu {
		width: 6rem;
		height: auto;
		background: #FFFFFF;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		border-radius: 0.19rem;
		position: absolute;
		top: 1.11rem;
		left: 0;
		z-index: 11;
	}

	.cart-way .way-line {
		width: 100%;
		height: 1rem;
		display: flex;
		justify-content: space-between;
		position: relative;
		line-height: 1rem;
		font-size: 0.3rem;
		padding: 0 0.3rem;
		border-radius: 0.19rem;
	}

	.way-line .way-name {
		color: #1A1A1A;
	}

	.way-line .way-price {
		color: #C25E4D;
	}

	.way-price span {
		color: #CCCCCC;
	}

	.showwaymenu .way-line:hover {
		background: #C25E4D;
	}

	.showwaymenu .way-line:hover>div {
		color: #FFFFFF;
		display: block;
	}

	.showwaymenu .way-line:hover>span {
		color: #FFFFFF;
	}

	.showwaymenu .cannouse {
		background: #EEEEEE;
		color: #999999;
	}

	.cannouse .way-name {
		color: #999999;
	}

	.showwaymenu .cannouse:hover {
		background: #EEEEEE;
	}

	.cannouse .way-price {
		color: #999999;
	}

	.showwaymenu .cannouse:hover>div {
		color: #999999;
		display: block;
	}

	.showwaymenu .cannouse:hover>span {
		/* color: #C25E4D; */
	}

	.way-tips {
		position: absolute;
		width: 2.5rem;
		height: 0.7rem;
		line-height: 0.7rem;
		background: #C25E4D;
		color: #FFFFFF;
		right: -2.7rem;
		top: 0.15rem;
		border-radius: 0.13rem;
		display: none;
		text-align: center;
	}

	.showwaymenu .way-line:hover>.way-price>.way-tips {
		display: block;
	}

	.way-price span {
		text-decoration: line-through;
		font-size: 0.26rem;
	}

	.tips-div {
		position: absolute;
		width: 0;
		height: 0;
		border-top: 0.1rem solid transparent;
		border-bottom: 0.1rem solid transparent;
		border-right: 0.15rem solid #C25E4D;
		margin-left: -0.15rem;
		margin-top: 0.25rem;
	}

	.cart-box .del {
		width: 0.88rem;
		height: 0.88rem;
		margin-top: 0.73rem;
		float: right;
		margin-left: 2.65rem;
	}

	.del img {
		width: 0.44rem;
		height: 0.44rem;
		margin: 0 0.22rem;
	}

	.del .deltxt {
		width: 100%;
		height: 0.37rem;
		font-size: 0.26rem;
		color: #666666;
		text-align: center;
	}

	.paycart-btn {
		width: 100vw;
		height: 2.07rem;
		position: fixed;
		bottom: 0;
		left: 0;
		background: #FFFFFF;
		box-shadow: 0rem -0.15rem 0.3rem 0rem rgba(0, 0, 0, 0.1);
		display: flex;
		justify-content: space-between;
		padding: 0.48rem;
		z-index: 10;
	}

	.cart-bottom {
		width: 100%;
		max-width: 18.5rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.paycart-btn .paycart-txt {
		width: auto;
		height: 1.11rem;
		text-align: right;
		padding: 0.25rem 0;
	}
	.cart-bottom .youyuan{
		padding: 0;
	}

	.paycart-btn .paycart {
		width: 5.96rem;
		height: 1.26rem;
		background: #C25E4D;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.24);
		border-radius: 1.26rem;
		line-height: 1.26rem;
		text-align: center;
		color: #FFFFFF;
		font-size: 0.44rem;
	}

	.paycart-txt .paycart-price {
		height: 0.61rem;
		font-size: 0.44rem;
		color: #1A1A1A;
	}

	.paycart-price span {
		color: #C25E4D;
		font-weight: bold;
	}

	.paycart-txt .paycart-msg {
		height: 0.37rem;
		line-height: 0.37rem;
		font-size: 0.26rem;
		color: #666666;
	}

	.pcimg {
		display: block;
	}

	.phoneimg {
		display: none;
	}
	.modelname{
		line-height: 1.21rem;
		font-size: 0.41rem;
		font-weight: 400;
		color: #1A1A1A;
	}
	.modeltxt{
		width:84%;
		margin: 0 auto;
		line-height: 0.51rem;
		font-size: 0.3rem;
		margin-bottom: 0.3rem;
	}
	.modelbtn{
		width: 100%;
		line-height: 0.8rem;
		font-size: 0.41rem;
		text-align: center;
		font-weight: 400;
	}
	.bottommsg{
		width: 100%;
		height: auto;
		margin: 0.59rem auto;
		font-size: 0.26rem;
		text-align: center;
		color: #999999;
	}

	@media screen and (max-width:1000px) {
		.cart-title {
			padding: 0 0.19rem;
			margin: 0.56rem 0;
		}

		.pcimg {
			display: none;
		}

		.phoneimg {
			display: block;
		}

		.cart-box {
			/* justify-content:normal ; */
		}

		.cart-box .del {
			margin-left: 0;
		}

		.cart-box .cart-txt {
			width: calc(100% - 4rem);
			display: block;
			text-align: left;
		}

		.cart-box .cart-name {
			margin-top: 0;
			font-size: 0.41rem;
		}

		.cart-way .showwaymenu {
			top: 0.81rem;
			left: -0.5rem;
			padding: 0.3rem 0;
		}

		.cart-box .cart-price {
			margin-top: 0;
			font-size: 0.41rem;
			margin-top: 0.2rem;
		}

		.cart-way {
			width: 3.3rem;
			margin-top: 0;
			height: 0.81rem;
			line-height: 0.81rem;
			border-radius: 0.81rem;
			border: 0.01rem solid #333333;
			text-align: center;
			/* overflow: hidden; */
			display: flex;
		}

		.cart-way div {
			height: 1rem;
			line-height: 1rem;
			font-size: 0.3rem;
			overflow: hidden;
			color: #1A1A1A;
		}

		.cart-way div img {
			width: 0.61rem;
			height: 0.61rem;
			margin: 0.25rem 0;
		}
		.cart-way .div{
			height: 0.81rem;
			line-height: 0.81rem;
		}

		.cart-way .div img {
			margin: 0.2rem 0;
			margin-left: 0.1rem;
		}

		.cart-list {
			padding: 0.01rem 0.19rem 0.89rem 0.19rem;
		}

		.cart-box {
			padding: 0.46rem 0.3rem;
		}

		.ivu-modal-wrap .ivu-modal {
			width: 80%!important;
			margin: auto;
		}
		.modelbtn{
			margin-top: 0.5rem;
		}

		.meal_num_box{
			border: none;
		}
		.meal_num div img {
			width: 0.55rem;
			height: 0.55rem;
			margin: 0.05rem;
		}
		.meal_num div {
			width: 0.85rem;
			height: 0.85rem;
			line-height: 0.85rem;
		}
		.nosale .cart-name{
			color: #cccccc;
		}
		.nosale .cart-price{
			color: #cccccc;
		}
		.deltxts{
			font-size: 0.2rem;
			margin-left: 0.2rem;
			color: #999999;
		}
	}
	.deltxtsss{
		color: #999999;
		font-size: 0.3rem;
		margin-left: 0.3rem;
	}
</style>
