<template>
	<div class="prolist meallist">
		<div class="show_mb">
			<div class="mealbgimg">
				<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/meallist.png" alt="">
			</div>
			<div class="pro-list" v-for="(item,index) in goodslist" :key="index">
				<transition name="slide-fade1" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==0" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<transition name="slide-fade2" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==1" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<div class="pro-title">{{item.title}}</div>
				<div class="title-line"></div>
				<div class="pro-msg">{{item.comment}}<div v-if="index==0">除了应急包，我们还提供 AI 私人定制健康方案。<span style="color: #C25E4D;" @click="goxcx" v-if="!isAlipay">点此了解 <img style="width: 0.2rem;height: 0.3rem;" src="../../public/imgs/pro/more.png" alt=""></span></div></div>
				<div class="pro-goodslist">
					<div class="pro-goodsbox" v-for="(items,indexs) in item.mealList" :key="indexs" v-show="items.id!=148">
						<div class="sq" v-if="items.sell_out==1">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/sold-out-icon@2x.png" alt="">
							<div class="sqtxt">售罄</div>
						</div>
						<div class="pro-name-box">
							<div class="pro-goodname" @click="godetail(items.id)">{{items.name}}</div>
						</div>
							<div class="pro-goodmsg">{{items.introduce}} </div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_url" alt="">
						</div>
						<div class="goods-daynum">

							<span style="font-size: 0.24rem;position: relative;" :class="items.pay_price!=items.total_price?'noss':''">
								￥<span style="font-size: 0.32rem;font-weight: bold;">{{items.pay_price}}</span>
								<del class="deltxtss dlp" v-if="items.pay_price!=items.total_price">￥{{items.total_price}}</del>
							</span>
							<div class="addcar" v-if="items.sell_out==0" @click="addthis(items.id)">加入购物车</div>
							<div class="addcar daohuo" v-if="items.sell_out==1" @click="daohuo(items.id)">{{isAlipay? '已售罄' : '到货通知'}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>




		<div class="show_pc detail_pc" id="all">
			<!-- <div class="list-top-bg1">
				<div class="list-top-center1">
					<div class="center-txt" style="">
						<div class="center-title"><p>生活小问题</p><p>一袋应急包解决</p></div>
						<div class="center-msg" style=""><p>忙碌的生活中，你会难免遇到熬夜、酗酒、睡不好或肠道不听话等健康小问题。别担心，我们为你度身定制了应对各种问题的场景化营养方案：5袋一小盒，每天一袋，5天还你健康好状态。</p></div>
					</div>
				</div>
			</div>
			<div class="pro-list" v-for="(item,index) in goodslist" :key="index">
				<div class="bg-bg" :id="item.id"></div>
				<transition name="slide-fade1" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==0" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<transition name="slide-fade2" :key="indexs" v-for="(items,indexs) in item.label_img">
					<img v-if="show&&items.is%2==1" :src="items.img" class="pimgs" :class="items.is==1?'pimg1':items.is==2?'pimg2':items.is==3?'pimg3':'pimg4'" alt="">
				</transition>
				<div class="pro-title">
					<div class="meal-pc-main-title-line"></div>
					<p>{{item.title}}</p>
				</div>
				<div class="title-line"></div>
				<div class="pro-msg fontsize">{{item.comment}}<span v-if="index==0">除了应急包，我们还提供 AI 私人定制健康方案。<span style="color: #C25E4D;cursor: pointer;" @click="golisojie">点此了解 <img style="width: 0.15rem;height: 0.2rem;" src="../../public/imgs/pro/more.png" alt=""></span></span></div>
				<div class="pro-goodslist">
					<div class="pro-goodsbox" :class="indexs%4==3?'noright':''" v-for="(items,indexs) in item.mealList" :key="indexs" v-show="items.id!=148">
						<div class="sq" v-if="items.sell_out==1">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/sold-out-icon@2x.png" alt="">
							<div class="sqtxt">售罄</div>
						</div>
						<div class="pro-name-box">
							<div class="pro-goodname" @click="godetail(items.id)">{{items.name}}</div>
						</div>
							<div class="pro-goodmsg">{{items.introduce}}</div>
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_url" alt="">
						</div>
						<div class="goods-daynum">
							<span style="font-size: 0.24rem;position: relative;" :class="items.pay_price!=items.total_price?'noss':''">￥<span style="font-size: 0.32rem;font-weight: bold;">{{items.pay_price}}</span><del class="deltxtss dlp" v-if="items.pay_price!=items.total_price">￥{{items.total_price}}</del></span>
							<div class="addcar" v-if="items.sell_out==0" @click="addthis(items.id)">加入购物车</div>
							<div class="addcar daohuo" v-if="items.sell_out==1" @click="daohuo(items.id)">到货通知</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pc_bottom">
				<div class="bimg1">
				</div>
				<div class="btitle">AI 定制营养</div>
				<div class="bimg2">
				</div>
				<div class="bmsg">
					<span>3分钟在线 AI 评估生成你的专属健康报告</span>
					<span>帮助你获得精准补剂方案</span>
				</div>
				<div class="bgoqus cursor" @click="goqus">AI 即刻定制</div>
			</div> -->



			<div class="list-top-bg1">
				<div class="list-top-center1">
					<div class="center-txt" style="">
						<div class="center-title"><p>5-Day Care Packs</p></div>
						<div class="center-msg" style=""><p>应急营养包  5天还你好状态</p></div>
						<div class="mis">
							<div class="mi-line">
								<img src="../../public/imgs/mi1.png" alt="" style="width:26px;margin-right: 18px;">
								<div>1盒5袋</div>
							</div>
							<div class="mi-line">
								<img src="../../public/imgs/mi2.png" alt="">
								<div>每日1袋</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="recommend-box">
				<div class="ab-img m1">
					<img src="../../public/imgs/prolist/m1.png" alt="">
				</div>
				<div class="pro-bg-title" style="display: inline-block;">
					<div class="pro-bg-name">Best Sellers</div>
					<div class="pro-bg-msg" style="text-indent: 0;text-align: center;">必Buy推荐</div>
				</div>
				<div class="recommend-list">
					<div class="recommend-pro">
						<div class="recommend-top cursor" style="background: #CEBFB7;" @click="godetail(100)">
							<img src="../../public/imgs/jj.png" alt="">
						</div>
						<div class="recommend-name">
							<p class="recommend-title Evolve">Hangover Prevention Kit</p>
							<p class="recommend-msg">「酒精消消乐」</p>
						</div>
						<div class="recommend-price">
							<div class="recommend-pleft"><span>¥ </span>62.5</div>
							<div class="recommend-pright">
								<p style="text-decoration: line-through;">¥ 333.25</p>
								<p>销售1000+</p>
							</div>
						</div>
						<div class="recommend-gocart cursor" @click="addthis(100)">
							<div>加入购物车</div>
							<img src="../../public/imgs/pro/more.png" alt="">
						</div>
					</div>
					<div class="recommend-pro">
						<div class="recommend-top cursor" style="background: #F0DEB3;" @click="godetail(124)">
							<img src="../../public/imgs/jl.png" alt="">
						</div>
						<div class="recommend-name">
							<p class="recommend-title Evolve">Refreshing Kit</p>
							<p class="recommend-msg">脑内「马杀鸡」</p>
						</div>
						<div class="recommend-price">
							<div class="recommend-pleft"><span>¥ </span>22.5</div>
							<div class="recommend-pright">
								<p style="text-decoration: line-through;">¥ 333.25</p>
								<p>销售1000+</p>
							</div>
						</div>
						<div class="recommend-gocart cursor" @click="addthis(124)">
							<div>加入购物车</div>
							<img src="../../public/imgs/pro/more.png" alt="">
						</div>
					</div>
					<div class="recommend-pro">
						<div class="recommend-top cursor" style="background: #AFA3B4;" @click="godetail(104)">
							<img src="../../public/imgs/sm.png" alt="">
						</div>
						<div class="recommend-name">
							<p class="recommend-title Evolve">Dreamwell Kit</p>
							<p class="recommend-msg">「舒压放轻松」</p>
						</div>
						<div class="recommend-price">
							<div class="recommend-pleft"><span>¥ </span>37.5</div>
							<div class="recommend-pright">
								<p style="text-decoration: line-through;">¥ 333.25</p>
								<p>销售1000+</p>
							</div>
						</div>
						<div class="recommend-gocart cursor" @click="addthis(104)">
							<div>加入购物车</div>
							<img src="../../public/imgs/pro/more.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="pro-list-box pro-list-box5">
				<div class="ab-img m3">
					<img src="../../public/imgs/prolist/m3.png" alt="">
				</div>
				<div class="ab-img m2">
					<img src="../../public/imgs/prolist/m2.png" alt="">
				</div>
				<div class="pro-lis-bg-box">
					<div class="pro-bg-title">
						<div class="pro-bg-name">Product List</div>
						<div class="pro-bg-msg">{{goodslist[0].title}}</div>
					</div>
					<div class="pro-bg-txt">
						{{goodslist[0].comment}}<span>除了应急包，我们还提供 AI 私人定制健康方案。<span style="color: #C25E4D;cursor: pointer;" @click="golisojie">点此了解 <img style="width: 0.15rem;height: 0.2rem;" src="../../public/imgs/pro/more.png" alt=""></span></span>
					</div>
				</div>
				<div class="pro-list-pros">
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox" v-for="(items,indexs) in goodslist[0].mealList" :key="indexs" v-show="items.id!=148">
						<div class="sq" v-if="items.sell_out==1">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/sold-out-icon@2x.png" alt="">
							<div class="sqtxt">售罄</div>
						</div>
						<!-- <div class="pro-name-box">
							<div class="pro-goodname" @click="godetail(items.id)">{{items.name}}</div>
						</div> -->
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_url" alt="">
						</div>
							<div class="pro-goodmsg">{{items.introduce}}</div>
						<div class="goods-daynum">
							<span style="font-size: 0.24rem;position: relative;font-weight: bold;" :class="items.pay_price!=items.total_price?'noss':''">￥<span style="font-size: 0.32rem;font-weight: bold;">{{items.pay_price}}</span><del class="deltxtss dlp" v-if="items.pay_price!=items.total_price">￥{{items.total_price}}</del></span>
							<div class="addcar" v-if="items.sell_out==0" @click="addthis(items.id)">加入购物车</div>
							<div class="addcar daohuo" v-if="items.sell_out==1" @click="daohuo(items.id)">{{isAlipay? '已售罄' : '到货通知'}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="plist-h3" style="margin-top: 123px;">
				<img src="../../public/imgs/h3.png" alt="">
			</div>
			<div class="pro-list-box pro-list-box6">
				<div class="ab-img m4">
					<img src="../../public/imgs/prolist/m4.png" alt="">
				</div>
				<div class="ab-img m5">
					<img src="../../public/imgs/prolist/m5.png" alt="">
				</div>
				<div class="pro-lis-bg-box">
					<div class="pro-bg-title">
						<div class="pro-bg-name">Special Care Kits</div>
						<div class="pro-bg-msg">{{goodslist[1].title}}</div>
					</div>
					<div class="pro-bg-txt">
						{{goodslist[1].comment}}
					</div>
				</div>
				<div class="pro-list-pros">
					<div class="pro-goodsbox" :class="indexs%4==3?'noright':''" v-for="(items,indexs) in goodslist[1].mealList" :key="indexs" v-show="items.id!=148&&indexs<2">
						<div class="sq" v-if="items.sell_out==1">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/sold-out-icon@2x.png" alt="">
							<div class="sqtxt">售罄</div>
						</div>
						<!-- <div class="pro-name-box">
							<div class="pro-goodname" @click="godetail(items.id)">{{items.name}}</div>
						</div>
							<div class="pro-goodmsg">{{items.introduce}}</div> -->
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_url" alt="">
						</div>
							<div class="pro-goodmsg">{{items.introduce}}</div>
						<div class="goods-daynum">
							<span style="font-size: 0.24rem;position: relative;font-weight: bold;" :class="items.pay_price!=items.total_price?'noss':''">￥<span style="font-size: 0.32rem;font-weight: bold;">{{items.pay_price}}</span><del class="deltxtss dlp" v-if="items.pay_price!=items.total_price">￥{{items.total_price}}</del></span>
							<div class="addcar" v-if="items.sell_out==0" @click="addthis(items.id)">加入购物车</div>
							<div class="addcar daohuo" v-if="items.sell_out==1" @click="daohuo(items.id)">{{isAlipay? '已售罄' : '到货通知'}}</div>
						</div>
					</div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox pro-goodsbox-none"></div>
					<div class="pro-goodsbox" :class="indexs%4==3?'noright':''" v-for="(items,indexs) in goodslist[1].mealList" :key="indexs" v-show="items.id!=148&&indexs>=2">
						<div class="sq" v-if="items.sell_out==1">
							<img src="https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/sold-out-icon@2x.png" alt="">
							<div class="sqtxt">售罄</div>
						</div>
						<!-- <div class="pro-name-box">
							<div class="pro-goodname" @click="godetail(items.id)">{{items.name}}</div>
						</div>
							<div class="pro-goodmsg">{{items.introduce}}</div> -->
						<div class="pro-img" @click="godetail(items.id)">
							<img :src="items.img_url" alt="">
						</div>
							<div class="pro-goodmsg">{{items.introduce}}</div>
						<div class="goods-daynum">
							<span style="font-size: 0.24rem;position: relative;font-weight: bold;" :class="items.pay_price!=items.total_price?'noss':''">￥<span style="font-size: 0.32rem;font-weight: bold;">{{items.pay_price}}</span><del class="deltxtss dlp" v-if="items.pay_price!=items.total_price">￥{{items.total_price}}</del></span>
							<div class="addcar" v-if="items.sell_out==0" @click="addthis(items.id)">加入购物车</div>
							<div class="addcar daohuo" v-if="items.sell_out==1" @click="daohuo(items.id)">{{isAlipay? '已售罄' : '到货通知'}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="list-bottom-bg" style="margin-top: 164px;">
				<img src="../../public/imgs/prolist/pbg.png" alt="">
				<div class="lbottom-box">
					<div class="lb-title">
						<p>告别盲从，只需3分钟</p>
						<p>已为 <span>200,000+</span> 国人提供个性化营养方案</p>
					</div>
					<a href="/home" class="pro-godingzhi">
						<div>开始 AI 定制</div>
						<img src="../../public/imgs/index/more.png" alt="">
					</a>
				</div>
			</div>






		</div>
		<!-- <foot></foot> -->
	</div>
</template>

<script>
	// import foot from '../components/footer.vue'
	export default {
		name: 'meal_list',
		components: {
			// foot
		},
		data: () => ({
			info: '',
			tid:'all',
			goodslist: '',
			puttop:false,
			show: false,
			isAlipay: navigator.userAgent.indexOf('AliApp') > -1 ? true : false
		}),
		mounted() {
			var info = localStorage.getItem('info')?JSON.parse(localStorage.getItem('info')):''
			this.info = info
			window.addEventListener('scroll', this.handleScroll, true)
			var that = this
			setTimeout(function() {
				that.show = true
			}, 600)
			this.$ajax.post(this.API.api.apiurl.getGoodsList, {
					cid: 0
				})
				.then(res => {
					////console.log(res)
					var data = res.data
					if (data.code == 0) {
						//console.log(data)
						// var list = data.data
						// list.sort(that.compare('id'))
						// that.goodslist = list
					} else {
						that.$Message.info('错误：' + data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
				//获取公告列表 （同第七周 获取支付后公告）
				// this.$ajax.post(this.API.api.apiurl.getNoticeList, {
				// 		type:5,
				// 	},{
				// 					headers:{
				// 						'Authorization':'Bearer '+that.info.token,
				// 					}
				// 				})
				// 	.then(res => {
				// 		////console.log(res)
				// 		var data = res.data
				// 		if (data.code == 0) {
				// 			//console.log(data)
				// 		} else {
				// 			that.$Message.info('错误：' + res.data.message)
				// 		}
				// 	})
				// 	.catch(error => {
				// 		//console.log(error)
				// 	})
				//应急包列表
				this.$ajax.post(this.API.api.apiurl.getJuDaiMealList, {
				})
				.then(res => {
					////console.log(res)
					var data = res.data
					if (data.code == 0) {
						//console.log(data)
						var list = data.data
						list.sort(that.compare('id'))
						list.forEach((item,index)=>{
							if(index==0){
								item.label_img=[
									{img:"https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/48bf9d00049afd6f75e7d0318386b057.png",is: "1"},
									{img: "https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/c8757878fffe29eb2b42d59338aef9f0.png",is: "2"},
									{img: "https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/ae4a9977117a00be9f7f8fc1e9b8178f.png",is: "3"},
								];
							}else if(index==1){
								item.label_img=[
									{img: "https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/fcfae5e3dabe98e2ed8a3e54ba3849bc.png",is: "2"},
									{img: "https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/275c794f3f0578e3c3beaaa3e9bcd0ab.png",is: "3"},
								];
							}else if(index==2){
								item.label_img=[
									{img:"https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/7cacda50e69a8b391ee35a3485d90e35.png",is: "1"},
									{img: "https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/4916ac9b7ada5abe18518fd39d887335.png",is: "2"},
									{img: "https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/uploads/admin/add_label/images/20200710/2c7c8f947ff3366daffb678972a4ed8f.png",is: "3"},
								];
							}
						})
						var arr = []
						var mealList = list[0].mealList
						for(var delbaibao=0;delbaibao<mealList.length;delbaibao++){
							if(mealList[delbaibao].id!=148){
								arr.push(mealList[delbaibao])
							}
						}
						// mealList.forEach((items)=>{
						// 	if(items.id==148){
						// 		//console.log('baibao')
						// 	}else{
						// 		arr.push(items)
						// 	}
						// })
						list[0].mealList = mealList.reverse()
						//console.log(list)
						that.goodslist = list
					} else {
						that.$Message.info('错误：' + data.message)
					}
				})
				.catch(error => {
					that.$Message.info(error)
				})
		},
		methods: {
			goxcx(){
				const sourceId = localStorage.getItem('sourceId')
				window.location.href= sourceId ? "https://api.xiaojudai.cn/api/s/27?source_id=" + sourceId : "https://api.xiaojudai.cn/api/s/2"
			},
			golisojie(){
				this.$router.push({
					path:'home'
				})
			},
			daohuo(id){
				var that = this
				if(that.isAlipay) return
				if(!this.info){
					that.$Message.info('请先登录')
					that.$router.push({
						path:'login',
						query:{
							way:'care-packs'
						}
					})
				}else{
					this.$ajax.post(this.API.api.apiurl.saveTheArrivalNotice, {
							type:2,
							ids:[id],
						},{
										headers:{
											'Authorization':'Bearer '+that.info.token,
										}
									})
						.then(res => {
							////console.log(res)
							var data = res.data
							if (data.code == 0) {
								//console.log(data)
								window.location.href = 'https://mmbizurl.cn/s/yiDciNLJd'
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			addthis(id){
				var that = this
				if(!this.info){
					that.$Message.info('请先登录')
					that.$router.push({
						path:'login',
						query:{
							way:'care-packs'
						}
					})
				}else{
					this.$ajax.post(this.API.api.apiurl.addCart, {
							// member_id: that.info.member_id,
							source: 2,
							buy_id: '',
							count_id: '',
							meal_id: id,
							meal_type: 0,
							goods_id: '',
							goods_num: '',
							is_pro: 1
						},{
									headers:{
										'Authorization':'Bearer '+that.info.token,
									}
								})
						.then(res => {
							////console.log(res)
							if ( res.data.code == 0) {
								//console.log(res.data)
								that.$Message.info(res.data.message)
								var l = Number(localStorage.getItem('clength'))
								localStorage.setItem('clength',l+1)
							} else {
								that.$Message.info('错误：' + res.data.message)
							}
						})
						.catch(error => {
							console.log(error)
						})
				}
			},
			compare(key){
				return function (o1,o2){
					var a=o1[key],b=o2[key];
					return a-b
				}
			},
			handleScroll() {
			//方法一
			//var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
			////console.log(top)

			//方法二
			//如果获取不到高度可换成
				// this.nextTick(()=>{
					var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
					if(top>350){
						this.puttop=true
					}else{
						this.puttop=false
					}
				// })
			},
			goqus(){
				this.$router.push({
					path:'home'
				})
			},
			godetail(id) {
				this.$router.push({
					path: 'meal-detail',
					query: {
						id: id
					}
				})
			}
		},
	}
</script>

<style>
	.meallist .center-msg{
		margin-top: 16px;
	}
	.mis{
		width: auto;
		height: auto;
		margin-top: 64px;
	}
	.mi-line{
		display: flex;
		justify-content: left;
		align-items: center;
		font-size: 18px;
		margin-bottom: 17px;
	}
	.mi-line img{
		width: 24px;
		height: auto;
		margin-right: 20px;
	}
	.meallist .ab-img img{
		width: 100%;
	}
	.m1{
		width: 219px;
		right: -100px;
		top: -90px;
	}
	.m2{
		width: 217px;
		left: -186px;
		top: 0;
	}
	.m3{
		width: 333px;
		right: -161px;
		top: 820px;
	}
	.m4{
		width: 231px;
		right: -122px;
		top: -40px;
	}
	.m5{
		width: 217px;
		left: -184px;
		top: 400px;
	}
	.recommend-price{
		width: 100%;
		display: flex;
		justify-content: left;
		text-align: left;
		align-items: center;
		color: #1A1A1A;
		margin: 20px 0 30px 0;
		margin-left:11px;
	}
	.recommend-pleft{
		font-size: 32px;
		font-weight: bold;
	}
	.recommend-pleft span{
		font-size: 18px;
	}
	.recommend-pright{
		color: #999999;
		font-size: 12px;
		margin-left: 10px;
	}
	.recommend-title{
		font-size: 24px;
	}
	.recommend-msg{
		font-size: 18px;
	}
	.recommend-gocart{
		width: 100%;
		height: 45px;
		border-radius: 45px;
		border: 2px solid #333333;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		color: #1A1A1A;
		font-weight: Medium;
	}
	.recommend-gocart img{
		width: 8px;
		height: auto;
		margin-left: 8px;
	}
	.recommend-name{
		width: 100%;
		height: auto;
		text-align: left;
		margin-left:11px;
	}
	.recommend-list{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		margin-top: 64px;
		height: auto;
		display: flex;
		justify-content: space-between;
	}
	.recommend-pro{
		width: 333px;
		height: auto;
	}
	.recommend-top{
		width: 333px;
		height: 176px;
		border-radius: 10px;
		position: relative;
		margin-bottom: 91px;
	}
	.recommend-top img{
		width: 156.4px;
		height: 150px;
		position: absolute;
		top: 68px;
		left: 50%;
		margin-left: -78.2px;
	}
	.recommend-box{
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		height: auto;
		text-align: center;
		margin-top: 164px;
		position: relative;
	}
	.pro-list-box5 .pro-lis-bg-box{
		height: 625px;
		background: #C6D0D7;
		left: 0;
		padding-left: 77px;
	}
	.pro-list-box6 .pro-lis-bg-box{
		height: 468px;
		background: #B9C7BB;
		left: auto;
		right: 0;
		padding-left: 169px;
	}
	.addcar{
		width: auto;
		/* height: 0.4rem; */
		/* line-height: 0.4rem; */
		padding: 0 0.2rem;
		border-radius: 0.4rem;
		background: #C25E4D;
		color: #FFFFFF;
		cursor: pointer;
	}
	.daohuo{
		border: 0.01rem solid #C25E4D;
		color: #C25E4D;
		background: #FFFFFF;
	}

	/* 可以设置不同的进入和离开动画 */
	/* 设置持续时间和动画函数 */
	.slide-fade1-enter-active {
		transition: all .8s ease;
	}

	.slide-fade1-leave-active {
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade1-enter,
	.slide-fade1-leave-to {
		transform: translateX(40px);
		opacity: 0;
	}

	.slide-fade2-enter-active {
		transition: all .8s ease;
	}

	.slide-fade2-leave-active {
		transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
	}

	.slide-fade2-enter,
	.slide-fade2-leave-to {
		transform: translateX(-40px);
		opacity: 0;
	}

	.dlp{
		position: absolute;
		right: 0;
		top: -0.3rem;
	}

	.prolist {
		width: 100%;
		height: auto;
		overflow: hidden;
	}

	.pro-list {
		width: 100%;
		margin: 0 auto;
		padding: 0 0.45rem;
		margin-bottom: 3.25rem;
		position: relative;
	}

	.pro-title {
		width: 5.67rem;
		height: 1.24rem;
		line-height: 1.24rem;
		color: #1A1A1A;
		font-size: 0.64rem;
		font-weight: 400;
		margin: 0 auto;
		margin-top: 0.91rem;
	}

	.title-line {
		width: 1.1rem;
		height: 0.14rem;
		background: #C25E4D;
		border-radius: 0.14rem;
		margin: 0 auto;
		margin-top: 0.23rem;
	}


	.pro-goodslist {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 1.13rem;
	}

	.meallist .pro-goodsbox {
		width: calc(50% - 0.2rem);
		height: auto;
		background: #FFFFFF;
		box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.1);
		border-radius: 0.17rem;
		padding: 0.3rem;
		margin-bottom: 0.4rem;
		position: relative;
	}
	.sq{
		position: absolute;
		top: 0;
		right: 0;
		width: 1.5rem;
		height: 1.5rem;
	}
	.sq img{
		width: 100%;
		height: 100%;
	}
	.sq .sqtxt{
		position: absolute;
		width: 1rem;
		line-height: 1rem;
		font-size: 0.3rem;
		/* font-weight: bold; */
		color: #1A1A1A;
		top: 0;
		right: 0;
		transform: rotate(45deg);
	}
	.pro-goodname{
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.pro-goodmsg{
		width: 100%;
		height: auto;
		line-height: 0.41rem;
		font-size: 0.26rem;
		text-align: left;
		margin-top: 0.1rem;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.meallist .pro-name-box {
		width: 100%;
		/* height: 0.93rem;
		line-height: 0.93rem; */
		font-size: 0.38rem;
		font-size: 20px;
		color: #1A1A1A;
		font-weight: bold;
		margin-top: 0;
		overflow: hidden;
	}

	.meallist .detail_pc .pro-img {
		width: 100%;
		height: 3.16rem;
		height: auto;
		margin-top: 0;
		padding-bottom: 0.32rem;
		border-bottom: none;
	}

	.prolist .pro-img img {
		width: 100%;
		height: auto;
		cursor: pointer;
		border-radius: 10px 10px 0 0;
	}

	.goods-daynum {
		width: 100%;
		height: 0.81rem;
		line-height: 0.81rem;
		font-size: 0.3rem;
		margin-top: 0.44rem;
		text-align: left;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	.goods-daynum .goods-right img {
		width: 0.4rem;
		height: 0.4rem;
		margin: 0.105rem 0;
	}

	.pimgs {
		position: absolute;
		width: 3rem;
		height: auto;
	}

	.pimg1 {
		top: 0;
		left: -1.05rem;
	}

	.pimg2 {
		top: 0;
		right: -1.05rem;
	}

	.pimg3 {
		width: 2.5rem;
		top: 4.8rem;
		left: -1.45rem;
	}

	.pimg4 {
		width: 2.5rem;
		top: 4.8rem;
		right: -1.45rem;
	}



	/* pc */
	.list-top-bg1{
		width: 100%;
		height: 6.48rem;
		height: 500px;
		background: #F6F5F8;
		background: #E7E7E7;
	}
	.list-top-center1{
		width: 22.22rem;
		height: 6.48rem;
		height: 500px;
		margin: 0 auto;
		background: url(https://nutripacks-static.oss-cn-shenzhen.aliyuncs.com/static/common/images/official-website/imgs/list_bg1.png);
		background: url(../../public/imgs/banner.png);
		background-repeat: no-repeat;
		background-size: 18.5rem 6.48rem ;
		background-position: right top;
		background-size: 1140px 400px ;
		background-size: 1200px 500px ;
		background-position: right center;
		/* padding-top: 1.3rem; */

		display: flex;
		align-items: center;
	}
	.tp-list{
		width: 100%;
		height: 1.7rem;
		margin: 0 auto;
		background: #FFFFFF;
		display: flex;
		justify-content: center;
		z-index: 8;
	}
	.puttop{
		width: 100vw;
		height: 1.7rem;
		background: #FFFFFF;
		position: fixed;
		top: 1.02rem;
		left: 0;
		box-shadow: 0rem 0.1rem 0.2rem 0rem #Efefef;
	}
	.maolist{
		width: 18.5rem;
		height: 1.7rem;
		margin: 0 auto;
		display: flex;
		justify-content: left;
	}
	/* .ivu-anchor-ink{
		display: none;
	} */
	.maolist a{
		padding: 0;
		width: auto;
		padding: 0 0.1rem;
		height: 0.5rem;
		line-height: 0.5rem;
		margin: 0.6rem 0.595rem;
		font-size: 0.3rem;
		color: #1A1A1A;
	}
	.maolist a:hover{
		color: #1A1A1A!important;
		text-decoration: none!important;
	}
	.maolist .active{
		color: #1A1A1A;
		height: 0.5rem;
		line-height: 0.5rem;
		font-weight: bold;
		border-bottom: 0.07rem solid #C25E4D;
		text-decoration: none;
	}
	/* .ivu-affix{
		top: 1.02rem!important;
		width: 100vw!important;
	}
	.ivu-anchor-link-title{
		color: #1A1A1A!important;
		text-decoration: none!important;
	} */
	.detail_pc .pro-list{
		width: 18.5rem;
		height: auto;
		margin: 0 auto;
		padding: 0;
		/* padding-top: 1.19rem;
		margin-bottom: 1.74rem; */
		padding-top: 0;
		margin-bottom: 0;
	}
	.meallist .detail_pc .pro-goodsbox{
		width: 4.2rem;
		padding: 0.44rem 0.28rem;
		padding: 0;
		margin-right: 0.56rem;
		margin-bottom: 0.61rem;
	}
	.meallist .detail_pc .pro-goodmsg{
		width: calc(100% - 0.56rem);
		margin: 0 auto;
		margin-top: 0.1rem;
		text-align: left;
	}
	.detail_pc .prolist .pro-name-box{
		line-height: 0.52rem;
		height: 0.52rem;
		font-size: 0.37rem;
		margin-top: 0;
	}
	.detail_pc .meallist .pro-img{
		/* height: 2.62rem; */
		margin-top: 0.32rem;
		padding-bottom: 0.3rem;
	}
	.meallist .detail_pc .goods-daynum{
		width: calc(100% - 0.56rem);
		height: 0.5rem;
		font-size: 0.26rem;
		margin: 0.44rem 0.28rem;
		line-height: 0.5rem;
		cursor: auto;
	}
	.detail_pc .goods-daynum .goods-right img{
		width: 0.3rem;
		height: 0.3rem;
		margin: 0.035rem 0;
	}
	.bgoqus{
		width: 4.2rem;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.3rem;
		text-align: center;
		color: #FFFFFF;
		background: #C25E4D;
		box-shadow: 0rem 0.15rem 0.3rem 0rem rgba(194, 94, 77, 0.25);
		border-radius: 1rem;
		margin: 0.59rem auto 0.89rem auto;
	}
	.meallist .detail_pc .goods-daynum .noss{
		line-height: 0.7rem;
	}
	.detail_pc .dlp {
		top: -0.15rem;
	}
	.goods-daynum .noss{
		line-height: 1.2rem;
	}
	.dlp {
		top: -0.05rem;
	}
	.mealbgimg{
		width: 100%;
		height: auto;
	}
	.mealbgimg img{
		width: 100vw;
		height: auto;
	}

	@media screen and (min-width:1001px) and (max-width:1439px){

		.list-top-center1{
			width: 1000px;
		}
	}
	@media screen and (min-width:1440px) {
		.list-top-center1{
			width: 1200px;
		}
	}
</style>
