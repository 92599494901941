<template>
  <div class="nutrition-query">
    <div class="top">
      <img class="top-banner" src="../../../public/imgs/nutritionQuery/index_banner.png">
      <a @click="goSearch">
        <img class="search-icon" src="../../../public/imgs/nutritionQuery/search-icon.png">
        <span>请输入食物名称</span>
      </a>
    </div>
    <div class="content">
      <div class="content-title">食品分类</div>
      <div class="classification">
        <div class="classification-item" v-for="item in categoryList" :key="item.id" @click="goCategory(item.id)">
            <img :src="item.image_url">
          <div class="item-name">{{item.name}}</div>
        </div>
      </div>
      <div class="content-title" style="margin-top:50px">BE THERE</div>
      <div class="content-text">NUTRIPACKS 橘袋愿与自然、健康、活力一道陪伴在你身边，以全方位的定制服务支持更好的健康未来。</div>
      <button class="content-btn" @click="toMiniProgram">开始 AI 定制</button>
    </div>
    <img class="foot-image" src="../../../public/imgs/nutritionQuery/index_foot.png">
  </div>
</template>

<script>
  export default {
    data(){
      return {
        categoryList:[
        ]
      }
    },
    mounted(){
        document.title = "食品营养素查询"
        this.getData();
    },
    methods: {
      getData(){
            var that = this;
            this.$ajax.post(this.API.api.apiurl.getFoodCategory, {
            },{
            }).then(res => {
                that.categoryList = res.data.data;
            });
      },
      toMiniProgram(){
        if(navigator.userAgent.indexOf('AliApp') > -1) {
						console.log('支付宝小程序')
						window.location.href = '/home'
					} else {
            const sourceId = localStorage.getItem('sourceId')
						window.location.href= sourceId ? "https://api.xiaojudai.cn/api/s/27?source_id=" + sourceId : "https://api.xiaojudai.cn/api/s/2"
					}
      },
      goSearch() {
        this.$router.push({
					path: 'food-search'
				})
      },
      goCategory(id) {
        this.$router.push({
					path: 'food-category?category_id='+id
				})
      }
    }

  }
</script>

<style lang="scss" scoped>
  .nutrition-query {

    .top {
      position: relative;

      .top-banner {
        width: 100%;
        height:auto ;
      }

      a {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.75rem;
        display: flex !important;
        align-items: center;
        width: 8.93rem;
        height: 1.07rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.05rem 0.21rem 0rem rgba(0, 0, 0, 0.1);
        border-radius: 0.53rem;

        .search-icon {
          width: 0.32rem;
          height: 0.32rem;
          margin: 0 0.32rem;
        }

        span {
          font-size: 0.35rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: #ffffff;
        border-radius: 1.33rem 1.33rem 0rem 0rem;
        position: relative;
        margin-top: -1.33rem;
        padding: 1.33rem 0 0.45rem 0;
        box-sizing: border-box;

        .content-title {
          position: relative;
          font-size: 0.85rem;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN,PingFangSC-Semibold, PingFang SC;
          font-weight: bold;
          color: #141414;
          text-align: left;
          margin-left: 0.53rem;
        }

        .content-title::before{
          position: absolute;
          content: "";
          left: 0.16rem;
          bottom: -0.79rem;
          width: 1.17rem;
          height: 0.16rem;
          background: #C25E4D;
          border-radius: 0.08rem;
        }

        .content-text {
          width: 8.29rem;
          font-size: 0.4rem;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #454645;
          line-height: 0.71rem;
          text-align: left;
          margin: 1.73rem 0 0 0.53rem;
        }

        .content-btn {
          width: 4.81rem;
          height: 1.17rem;
          background: #C25E4D;
          box-shadow: 0rem 0.11rem 0.16rem 0rem rgba(163, 68, 52, 0.26);
          border-radius: 0.59rem;
          font-size: 0.4rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 0.56rem;
          margin: 0.8rem 0 0 0.53rem;
        }

        .classification {
          display: flex;
          flex-wrap: wrap;
          margin: 1.27rem 0 0 0.53rem;
          width: 100%;

          .classification-item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 1.93rem;
            margin: 0.4rem 0.4rem 0 0;

            img {
              width: 100%;
              height: auto;
            }

            .item-name {
              margin-top: 0.19rem;
              font-size: 0.35rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }


      }

    .foot-image {
      width: 100%;
      height: auto;
    }
  }
</style>
